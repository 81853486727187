import { useState, useEffect, useCallback } from 'react';
import { Box, CircularProgress, Button } from '@mui/material';
import { green } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useStateContext } from "../contexts/ContextProvider";
// import { editLeaveType } from "../services/leaveService";

// const LeaveSettingsForm = () => {
const CustomDataGrid = ({ rows, setRows, otherColumns, backendCRUD, actions, extraButtons }) => {
  const gridColumns = otherColumns[0]
  let newRowColumns = otherColumns[1]

  // const [latestRow, setLatestRow] = useState();
  // const [editedRows, setEditedRows] = useState({rows: []});
  const [rowModesModel, setRowModesModel] = useState({});
  const [isInSaveMode, setIsInSaveMode] = useState(false);
  // const [pendingUpdate, setPendingUpdate] = useState(false);
  const { employee, isLoading, setIsLoading, snackbar, setSnackbar } = useStateContext(); 

  // useEffect(() => {
  //   if (editedRows.rows.length === 0 && latestRow ) {
  //     setEditedRows({ rows: [latestRow]});
  //   } else if (editedRows.rows.length !== 0 && latestRow ) {
  //     // setEditedRows({ rows: editedRows.rows.concat([(editedRows.rows.find((row) => row.id === latestRow.id)) ? latestRow : row])});
  //     const eRows = editedRows.rows
  //     const index = eRows.findIndex(dict => dict.id === latestRow.id);

  //     if (index !== -1) {
  //       eRows[index] = latestRow;
  //     } else {
  //       eRows.push(latestRow);
  //     }

  //     setEditedRows({ rows: eRows});
  //   }

  //   // saveToBackend(editedRows);

  //   console.log("LR", latestRow);
  //   console.log("ERR", editedRows);
  // }, [latestRow]);


  const handleCloseSnackbar = () => setSnackbar(null);

  const handleSaveClick = (id) => () => {
    setIsInSaveMode(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  }

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);  

  const handleRowEditStop = (params, event) => {
    // console.log("PARAMS", params);
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => async () => {
    // setPendingUpdate(true);
    setIsLoading(true);
    
    const result = await backendCRUD["D"]( id , backendCRUD["TYPE"]);
    if (result.status === 200) {
      setSnackbar({ children: 'Leave setting successfully deleted', severity: 'success' });
      // setPendingUpdate(false);
      console.log(result);
      setRows(rows.filter((row) => row.id !== id));
    } else {
      setSnackbar({ children: "Error while deleting.", severity: 'error' });
    }
    setIsLoading(false);

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    setIsLoading(true);
    const updatedRow = (newRow.id !== 0) ? { ...newRow, isNew: false } : newRow;
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

    // setLatestRow(newRow);

    // setPendingUpdate(true);

    // saveToBackend(editedRows);
    // saveToBackend({rows: [updatedRow]});
    setTimeout(() => {
      saveToBackend({rows: [updatedRow], editor_employee_number: employee.employee_number});
    }, 2000)

    return updatedRow;
  };

  const saveToBackend = async (editedRows) => {
    let result;
    if (editedRows.rows[0]["isNew"] === false){
      result = await backendCRUD["U"]( editedRows );
    }
    else {
      result = await backendCRUD["C"]( editedRows );
    }

    if (result.status === 200) {
      setSnackbar({ children: 'Leave settings successfully edited', severity: 'success' });
      // setEditedRows({rows: []});
      // setPendingUpdate(false);
      setRows(result.data.data);
      // setRows(rows.map((row) => (row.id === editedRows.id ? editedRows : row)));
    } else {
      setSnackbar({ children: "Error while updating.", severity: 'error' });
    }

    setIsInSaveMode(false);
    setIsLoading(false);

}  

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const displayActions = actions ? actions : false

  const columns = displayActions 
                  ? gridColumns.concat([  
                    {
                      field: 'actions',
                      type: 'actions',
                      headerName: 'Actions',
                      width: 100,
                      cellClassName: 'actions',
                      getActions: ({ id }) => {
                        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                        if (isInEditMode) {
                          return [
                            <GridActionsCellItem
                              icon={<SaveIcon />}
                              label="Save"
                              sx={{
                                color: 'primary.main',
                              }}
                              onClick={handleSaveClick(id)}
                              // onClick={() => handleSaveClick(row)}
                            />,
                            <GridActionsCellItem
                              icon={<CancelIcon />}
                              label="Cancel"
                              className="textPrimary"
                              onClick={handleCancelClick(id)}
                              color="inherit"
                            />,
                          ];
                        }

                        if (isInSaveMode) {
                          return [
                            // <CircularProgress
                              // size={52}
                              // sx={{
                              //   color: green[500],
                              //   position: 'absolute',
                              //   top: -6,
                              //   left: -6,
                              //   zIndex: 1,
                              // }}
                            // />
                          <GridActionsCellItem
                            icon={<DonutLargeIcon />}
                            label="Loading"
                            className="textPrimary"
                            color="inherit"
                          />,
                          ];
                        }

                        return (
                          backendCRUD["D"] 
                          ? 
                          [<GridActionsCellItem
                              icon={<EditIcon />}
                              label="Edit"
                              className="textPrimary"
                              onClick={handleEditClick(id)}
                              color="inherit"
                            />,
                            <GridActionsCellItem
                              icon={<DeleteIcon />}
                              label="Delete"
                              onClick={handleDeleteClick(id)}
                              color="inherit"
                            />] 
                          : [<GridActionsCellItem
                              icon={<EditIcon />}
                              label="Edit"
                              className="textPrimary"
                              onClick={handleEditClick(id)}
                              color="inherit"
                            />,
                            ]
                          );
                      },
                    },
                  ])
                  : gridColumns;

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        // getRowId={(row) => row.id}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel, setIsLoading, newRowColumns, backendCRUD, extraButtons}, //, pendingUpdate, saveToBackend, editedRows, rows },
        }}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}    

      {/* Loading overlay and spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="spinner" style={{ animation: 'spin 1s infinite linear' }}></div>
          {/* <div className="spinner" ></div> */}
        </div>
      )}
    </Box>
  );  
};


function EditToolbar(props) {
  const { setRows, setRowModesModel, setIsLoading, newRowColumns, backendCRUD, extraButtons } = props;//, pendingUpdate, saveToBackend, editedRows, rows } = props;

  const handleClick = () => {

    // let newRow = Object.fromEntries(
    //   Object.entries(originalDict).map(([key, value]) => [key, ''])
    // );

    newRowColumns["isNew"] = true;
    const id = 0
    newRowColumns["id"] = id;

    setRows((oldRows) => [...oldRows, newRowColumns]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  const handleRunClick = async (func, func_action) => {
    setIsLoading(true);
    const result = await func(func_action);
    setRows(result.data.accrual_runs);
    setIsLoading(false);
  }

  return (
    <GridToolbarContainer>
      {backendCRUD["C"] && (      
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add record
        </Button>)
      }
      <GridToolbarExport />
      {extraButtons && extraButtons.map((button) => (
        <Button key={button.label} color="primary" startIcon={button.icon} onClick={() => handleRunClick(button.func, button.func_action)}>
          {button.label}
        </Button>
      ))}
      {/* {pendingUpdate && (
        <GridActionsCellItem
          icon={<SaveIcon />}
          label="Save"
          sx={{
            color: 'primary.main',
            alignItems: 'center'
          }}
          onClick={() => (saveToBackend(editedRows))}
        />
        // ,
        // <GridActionsCellItem
        //   icon={<CancelIcon />}
        //   label="Cancel"
        //   className="textPrimary"
        //   // onClick={() => (setRows(rows))}
        //   color="inherit"
        // />
      )} */}
    </GridToolbarContainer>
  );
}

export default CustomDataGrid;