import { useEffect, useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
  Button
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Popup from "../../components/Popup";
import LeaveApplicationForm from "../leave_application_form/leave_application_form";
import { useStateContext } from "../../contexts/ContextProvider";
import { workDuration, getLeaveTaken, getLeaveTypes, getLeaveBalances, getLeaveGroups,
        deleteLeaveRecord, editLeave, getApproveeLeaveTaken, getAccrualRuns } from "../../services/leaveService";
import { getEmployees } from "../../services/employeeService";
import { subSeconds, subDays } from 'date-fns'
import format from 'date-fns/format'


const Calendar = () => {
  const calendarRef = useRef(null); // Create a ref to access the FullCalendar instance
  // let initialEvents = [];
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const { currentEvents, setCurrentEvents, employee, employees, setEmployees, leaveTaken, setLeaveTaken, 
    leaveTypes, setLeaveTypes, setApproveeLeaveTaken, selectedLeaveType, setSelectedLeaveType, setResultMessage, 
    selectedLeave, setSelectedLeave, selectedEditLeave, setSelectedEditLeave, setLeaveBalances, openPopup, 
    setOpenPopup, openFailedPopup, setOpenFailedPopup, openDeleteEventPopup, setOpenDeleteEventPopup,
    range, setRange, leaveGroups, setLeaveGroups, setDuration, manualDuration, resultMessage, isLoading, setIsLoading, setAccrualRuns } = useStateContext(); 

  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const refPopup = useRef(null)
  const refFailedPopup = useRef(null)
  const refDeleteEventPopup = useRef(null)

  
  useEffect(() => {
    const fetchLeaveValues = async () => {
      setIsLoading(true);
      //set this from all leave taken if admin?
      let leave_taken = await getLeaveTaken(employee, leaveTaken);

      const leave_types = await getLeaveTypes();
      const balances = await getLeaveBalances();
      const leave_groups = await getLeaveGroups();
      const employees = await getEmployees();
      const approvee_leave_taken = await getApproveeLeaveTaken(employee);
      const accrual_runs = await getAccrualRuns();

      if(leave_taken){
        leave_taken = leave_taken.map((item) => ({
          ...item,
          title: leave_types.find(LTY_item => LTY_item.id === item.title)?.name
        }));
      };
      
      setLeaveTaken(leave_taken);
      setLeaveTypes(leave_types);
      setLeaveBalances(balances);
      setLeaveGroups(leave_groups);
      setEmployees(employees);
      setAccrualRuns(accrual_runs);
      setApproveeLeaveTaken(approvee_leave_taken);
      setIsLoading(false);
      setIsInitialLoading(false);
    };
    
    // initialEvents = fetchLeaveValues();
    if (leaveTaken.length === 0){
      fetchLeaveValues();
    } else {
      setIsLoading(false);
      setIsInitialLoading(false);
    }
  }, []);

  useEffect(() => {
    if (leaveGroups){
      let worked_duration = workDuration(range, selectedLeaveType, leaveTypes, manualDuration);
      setDuration(worked_duration);
      
      if (selectedEditLeave){
        const data = {"employee_number": employee.employee_number,
                    "edited_leave_id": selectedEditLeave.id,
                    "edited_leave_startStr": format(range[0].startDate, "yyyy-MM-dd"),
                    "edited_leave_endStr": format(range[0].endDate, "yyyy-MM-dd"),
                    "duration": worked_duration,
                    "selected_leave_type":selectedLeaveType
        }
                    
        const _editLeave = async (data) => {
          const edit_leave_response = await editLeave(data);

          if (edit_leave_response["status"] !== 200){
            setResultMessage(edit_leave_response["data"]["message"]);
            setOpenFailedPopup(true);

            // selectedEditLeave.event.setProp('title', 'New Title');
            // const calendarApi = calendarRef.current.getApi(); // Get the Calendar API
            // console.log("CA", calendarApi);
            // const eventToUpdate = calendarApi.getEventById(selectedEditLeave.id);
            // eventToUpdate.setStart(selectedEditLeave.startStr+'T00:00:00');
            // eventToUpdate.setEnd(selectedEditLeave.endStr+'T00:00:00');
            // calendarApi.updateEvent(eventToUpdate);
          }else{
            setLeaveBalances(edit_leave_response["data"]["new_leave_balances"]);
            setLeaveTaken(edit_leave_response["data"]["leave_taken"]);
          }

          setSelectedEditLeave(null);
        }

        _editLeave(data);
      }
    }

  }, [range, selectedEditLeave]);  

  useEffect(() => {}, [isLoading]);

  const handleDateClick = (selected) => {    
    // const calendarApi = selected.view.calendar;
    // calendarApi.unselect();
    
    setRange([{ 
      startDate: selected.start,
      endDate: subSeconds(selected.end, 1),
      key: 'selection' 
    }])

    setSelectedLeave(selected);
    // console.log(selected);
    setOpenPopup(true);
  };

  const handleEventClick = (selected) => {
    setSelectedLeave(selected);
    setOpenDeleteEventPopup(true);
  };

  const handleDeleteEvent = async () => {
    setIsLoading(true);
    const response = await deleteLeaveRecord(selectedLeave.event.id, "LEAVE TAKEN");
    
    if (response["status"] !== 200){
      setResultMessage(response["data"]["message"])
      setOpenFailedPopup(true);
    } else {
      selectedLeave.event.remove();
      setLeaveBalances(response["data"]["new_leave_balances"]);
    }
    setIsLoading(false);
    setOpenDeleteEventPopup(false);
  };

  const handleRetry = () => {
    setOpenFailedPopup(false);
    setOpenPopup(true);
  };

  const handleEventResize = async (info) => {
    const event = info.event;

    //to verify balance before sending to backend
    // const unedited_leave_type = leaveTypes.find(LTY_item => LTY_item.name === event.title)?.id;
    // const unedited_range = [{
    //   startDate: event.start,
    // const unedited_duration = workDuration(range, unedited_leave_type, leaveTypes);

    setRange([{ 
      startDate: event.start,
      endDate: subSeconds(event.end, 1),
      key: 'selection' 
    }])

    setSelectedEditLeave(event);
    setSelectedLeaveType(leaveTypes.find(LTY_item => LTY_item.name === event.title)?.id);
  };

  const handleEventDrop = (info) => {
    const event = info.event;

    setRange([{ 
      startDate: event.start,
      endDate: subSeconds(event.end, 1),
      key: 'selection' 
    }])

    setSelectedEditLeave(event);
    setSelectedLeaveType(leaveTypes.find(LTY_item => LTY_item.name === event.title)?.id);
  };

  const handleFailedPopupClick = (button) => {
    if (button === "retry"){
      handleRetry();
    }
    else if (button === "OK"){
      setOpenFailedPopup(false);

      // window.location.reload();
    }
  }

  if (isLoading & isInitialLoading) {
    return (
      <div>
        {/* Loading overlay and spinner */}
        {isLoading && (
          <div className="loading-overlay">
            <div className="spinner" style={{ animation: 'spin 1s infinite linear' }}></div>
            {/* <div className="spinner" ></div> */}
          </div>
        )}
      </div>
    );
  }
  
  return (
    <Box m="20px">        
      <Header title="Apply Leave" subtitle="Leave Application Page" />

      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        <Box
          flex="1 1 20%"
          backgroundColor={colors.primary[400]}
          p="15px"
          borderRadius="4px"
        >
          <Typography variant="h5">Events</Typography>
          <List>
            {currentEvents.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  margin: "10px 0",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={
                    <Typography>
                      {/* {format(event.start, "MMM d, yyyy")} {" - "} {format(subDays(event.end, 1), "MMM d, yyyy")} */}
                      {format(event.start, "MMM d, yyyy") === format(subDays(event.end, 1), "MMM d, yyyy") 
                        ? format(event.start, "MMM d, yyyy") 
                        : `${format(event.start, "MMM d, yyyy")} - ${format(subDays(event.end, 1), "MMM d, yyyy")}`}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            ref={calendarRef} 
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            // dragScroll={true}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setCurrentEvents(events)}
            initialEvents={leaveTaken}
            eventResize={handleEventResize}
            eventDrop={handleEventDrop}
            eventContent={(eventInfo) => {
              const { event } = eventInfo;
              const eventColor = leaveTaken.find((item) => item.id === parseInt(event.id))?.status === 'approved' ? 'green' : leaveTaken.find((item) => item.id === parseInt(event.id))?.status === 'declined' ? 'red' : 'blue'; //(event.id).toString()
              // const eventColor = event.extendedProps.color; // Assuming the color is stored in the extendedProps of the event
          
              return (
                <div
                  style={{
                    backgroundColor: eventColor,
                    color: 'white',
                    borderRadius: '3px',
                    padding: '0px',
                  }}
                >
                  {event.title}
                </div>
              );
            }}
            // eventRender={(info) => {
            //   const event = info.event;
            //   const customColor = event.status === 'approved' ? 'green' : 'red';
          
            //   return (
            //     info.render({ backgroundColor: customColor })
            //   );
            // }}
          />
        </Box>
        
      </Box>     {/* <div className="spinner" ></div> */}
        

      <div ref={refPopup}>
        {openPopup && 
          <Popup 
            openPopup = {openPopup}
            setOpenPopup = {setOpenPopup}
          >
            <LeaveApplicationForm 
              popupData = {selectedLeave}
            />
          </Popup>
        }
      </div>

      <div ref={refFailedPopup}>
        {openFailedPopup && 
          <Popup 
            openPopup = {openFailedPopup}
            setOpenPopup = {setOpenFailedPopup}
          >
            <Typography style={{ textAlign: 'center' }}>
              Failed 
              <br />
              {/* {console.log(selectedLeave)} */}
              {/* {selectedLeave.event.title} */}
              <br />
              {resultMessage}
            </Typography>
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={handleRetry} type="button" color="secondary" variant="contained">
                    Retry
                </Button>
                <Button onClick={() => handleFailedPopupClick("OK")} type="button" color="secondary" variant="contained">
                    Ok
                </Button>
            </Box>
          </Popup>
        }
      </div>

      <div ref={refDeleteEventPopup}>
        {openDeleteEventPopup && 
          <Popup 
            openPopup = {openDeleteEventPopup}
            setOpenPopup = {setOpenDeleteEventPopup}
          >
            <Typography style={{ textAlign: 'center' }}>
              Delete 
              <br />
              {selectedLeave.event.title}
              <br />
              {selectedLeave.event.startStr} - {selectedLeave.event.endStr}
            </Typography>
            <Box display="flex" justifyContent="space-between" width="100%" gap="10px" mt="20px">
                <Button onClick={() => setOpenDeleteEventPopup(false)} type="button" color="secondary" variant="contained">
                    No
                </Button>
                <Button onClick={handleDeleteEvent} type="button" color="secondary" variant="contained">
                    Ok
                </Button>
            </Box>
          </Popup>
        }
      </div>

      {/* Loading overlay and spinner */}
      {isLoading && (
          <div className="loading-overlay">
            <div className="spinner" style={{ animation: 'spin 1s infinite linear' }}></div>
            {/* <div className="spinner" ></div> */}
          </div>
        )}
    </Box>
  );
};

export default Calendar;
