import axios from "axios";

axios.defaults.withCredentials = true;  

export const register = async (employee) => {
  // axios.post('http://localhost/api/register', data, config)
  //   .then(response => {
  //     console.log("RESPONSE", response.data);
  //     return response
  //   })
  //   .catch(error => {
  //     console.error("ERROR REGISTER", error);
  //     return error
  //   });
  
    try {
      const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
      const csrfToken = tokenResponse.data.csrfToken;
      // console.log("CSRF TOKEN", csrfToken);
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          // Authorization: 'Bearer your_token_here',
          'X-CSRF-TOKEN': csrfToken
        }
      };

      const data = {
        "name" : employee.name,
        "email" : employee.email,
        "password" : employee.password
      };

      // console.log("DATA", data);

      const registerResponse = await axios.post('http://localhost/api/register', data, config);
      // console.log("RESPONSE", registerResponse.data);
      return registerResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const forgot_password = async (employee) => {
    try {
      const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
      const csrfToken = tokenResponse.data.csrfToken;
      // console.log("CSRF TOKEN", csrfToken);
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          // Authorization: 'Bearer your_token_here',
          'X-CSRF-TOKEN': csrfToken
        }
      };

      const data = {
        "email" : employee.email,
      };

      console.log("FP DATA", data);

      const registerResponse = await axios.post('http://localhost/api/forgot-password', data, config);
      console.log("FPRESPONSE", registerResponse);
      return registerResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const reset_password = async (employee) => {
    try {
      const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
      const csrfToken = tokenResponse.data.csrfToken;
      // console.log("CSRF TOKEN", csrfToken);
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          // Authorization: 'Bearer your_token_here',
          'X-CSRF-TOKEN': csrfToken
        }
      };

      const data = {
        "token" : employee.token,
        "email" : employee.email,
        "password" : employee.password,
        "password_confirmation" : employee.password_confirmation,
      };

      console.log("RP DATA", data);

      const registerResponse = await axios.post('http://localhost/api/reset-password', data, config);
      console.log("RPRESPONSE", registerResponse);
      return registerResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const login = async (employee) => {  
    try {
      const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
      const csrfToken = tokenResponse.data.csrfToken;
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          // Authorization: 'Bearer your_token_here',
          'X-CSRF-TOKEN': csrfToken
        }
      };

      const data = {
        // "name" : employee.name,
        "email" : employee.email,
        "password" : employee.password
      };

      // console.log("DATA", data);

      const loginResponse = await axios.post('http://localhost/api/login', data, config);
      // console.log("RESPONSE", loginResponse.data);
      return loginResponse

    } catch (error) {
      console.error("ERROR TRY", error);
      return error.response
    }
  };

export const verifyTokenAndGetUser = async (token) => {  
  try {
    const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
    const csrfToken = tokenResponse.data.csrfToken;
    const config = {
      headers: {
        // 'Content-Type': 'application/json',
        // Authorization: 'Bearer your_token_here',
        'X-CSRF-TOKEN': csrfToken
      }
    };

    const data = {
      "token" : token
    };

    // console.log("DATA", data);

    const loginResponse = await axios.post('http://localhost/api/login/token', data, config);
    // console.log("RESPONSE", loginResponse.data);
    return loginResponse

  } catch (error) {
    console.error("ERROR TRY", error);
    return error.response
  }
};

export const getEmployees = async () => {
  try {
      const response = await axios.get('http://localhost/api/employees/');
      console.log("getEmployees", response);

      return response.data.results;
  } catch (err) {
      // console.log(err);
      console.log("somthing Wrong get Employees");
  }
};