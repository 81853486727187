import axios from "axios";
import format from 'date-fns/format'
import { addDays } from 'date-fns'

export const addEvent = async (data, values) => {
    // 2024-03-11T13:00:00+02:00
    const selected = data["selected"];
    const leave_id = data["leave_id"];
    const title = values.leave_types.find(item => item.id === values.selected_leave_type)?.name

    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    calendarApi.addEvent({
        id: leave_id,
        title,
        start: values.pickedDateInitial,
        end: values.pickedDateFinal,
        allDay: selected.allDay,
    });

    // console.log(Object.keys(calendarApi))
    // console.log(calendarApi.getEvents());

    let result = {"success" : true, "message": "leave successfully applied"};
    return result;
}

export function updateEvent(eventToUpdate) {
    console.log("UE", eventToUpdate);
    const calendarApi = eventToUpdate.view.calendar;
    calendarApi.unselect();

    calendarApi.updateEvent(eventToUpdate);

    console.log(calendarApi.getEvents());

    let result = {"success" : true, "message": "leave successfully edited"};
    return result;
}

export const applyLeave = async (initial_data, values) => {  
    // console.log("AL", initial_data["selected"]);
    try {
        // console.log(values);
        const duration = initial_data["duration"];

        const available_balance = values.leave_balances[values.selected_leave_type]["available"]
        // const pending_balance  = values.leave_balances.find(item => item.leave_type === values.selected_leave_type)?.pending
        // const initial_leave_balance = available_balance + pending_balance;
    
        if ((available_balance - duration) < 0){
            let result = {"status" : false, 
                          "data" : {"message": "Not enough leave days left"}};
            return result;
        }

        //SEND TO BACKEND
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        const formData = new FormData();
        formData.append("employee_number", initial_data.employee_number);
        formData.append("leave_type_id", values.selected_leave_type);
        formData.append("start_date", values.pickedDateInitial);
        formData.append("end_date", values.pickedDateFinal);
        formData.append("duration", initial_data.duration);
        formData.append("application_reason", values.application_reason);
        formData.append("attachment", initial_data.attachment);
        formData.append("status", "pending");

        // const data = {
        //     "employee_number" : initial_data.employee_number,
        //     "leave_type_id" : values.selected_leave_type,
        //     "start_date" : values.pickedDateInitial,
        //     "end_date" : values.pickedDateFinal,
        //     "duration" : initial_data.duration,
        //     "application_reason" : values.application_reason,
        //     "attachment" : initial_data.attachment,
        //     "status" : "pending"
        // };

        // console.log("applyLeave", formData);

        const registerResponse = await axios.post('http://localhost/api/apply-leave', formData, config);

        console.log("applyResponse", registerResponse);

        const transformedResultsData = changeDatesInsideResultsFromStringFormat(registerResponse.data.employee_leave_taken);

        const transformedRegisterResponse = { ...registerResponse, data: { ...registerResponse.data, employee_leave_taken: transformedResultsData } }

        return transformedRegisterResponse
        // return registerResponse

    } catch (error) {
        console.error("applyLeave - ERROR TRY", error);
        return error.response
    }
};

export const editLeave = async (initial_data) => {    
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        const data = {
            "id" : initial_data["edited_leave_id"],
            "leave_type_id" : initial_data["selected_leave_type"],
            "start_date" : initial_data["edited_leave_startStr"],
            "end_date" : initial_data["edited_leave_endStr"],
            "duration" : initial_data["duration"],
            "application_reason" : initial_data["application_reason"],
            "attachement" : initial_data["attachement"],
            "status" : "pending"
        };

        console.log("editLeave - DATA", data);

        // return;

        const editResponse = await axios.put('http://localhost/api/edit-leave', data, config);
        console.log("editRESPONSE", editResponse);

        const transformedResultsData = changeDatesInsideResultsFromStringFormat(editResponse.data.employee_leave_taken);

        const transformedEditResponse = { ...editResponse, data: { ...editResponse.employee_leave_taken, employee_leave_taken: transformedResultsData } }

        console.log("transformedEditResponse", transformedEditResponse);
        return transformedEditResponse
        // return editResponse

    } catch (error) {
        console.error("editLeave - ERROR TRY", error);
        return error.response
    }
};

export const getLeaveTypes = async () => {
    try {
        const response = await axios.get('http://localhost/api/leave-types');
        console.log("getLeaveTypes", response);
        return response.data.results;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log("somthing Wrong leave types");
    }
};

export const addLeaveType = async (initial_data) => {    
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        console.log("GRID - addLeaveType", initial_data);

        const editResponse = await axios.post('http://localhost/api/add-leave-type', initial_data, config);
        // console.log("editResponse", editResponse);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("addLeaveType - ERROR TRY", error);
        return error.response
    }
};

export const editLeaveType = async (initial_data) => {    
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };


        // const data = {initial_data};
        // const data = {
        //     "id" : initial_data["id"],
        //     "name" : initial_data["name"],
        //     "accrual_type" : initial_data["accrual_type"],
        //     "accrual_days" : initial_data["accrual_days"],
        // };

        console.log("GRID - editLeaveType", initial_data);

        // return;

        const editResponse = await axios.put('http://localhost/api/edit-leave-type', initial_data, config);
        // console.log("editResponse", editResponse);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editLeaveType - ERROR TRY", error);
        return error.response
    }
};

export const deleteLeaveType = async (leave_id) => {
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        const data = {
            "leave_taken_id" : leave_id,
        };

        const response = await axios.delete('http://localhost/api/delete-leave', {...config, data: data,});
        console.log("deleteLeaveTaken");

        return response

    } catch (error) {
        console.error("deleteLeaveTaken - ERROR TRY", error);
        return error.response;
    }
}

export const getEmployeeLeaveBalances = async (employee) => {
    // console.log("EMPLOYEE LS", employee)
    try {
        const response = await axios.get('http://localhost/api/get-employee-leave-balances/'+employee.employee_number);
        let balances = response.data.results.balances;
        balances = JSON.parse(balances);
        console.log("getEmployeeLeaveBalances", response);

        return balances;
        // setUSerData(result.data.results)
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get Balances");
    }
}

export const getLeaveBalances = async () => {
    try {
        const response = await axios.get('http://localhost/api/leave-balances/');
        console.log("getLeaveBalances", response);

        return response.data.results;
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get Balances");
    }
}

export const addLeaveBalance = async (initial_data) => {    
    console.log("IDDD", initial_data); 
    const modified_data = {rows:[]};
    modified_data["rows"] = initial_data["rows"].map((in_data) => {
        
        const balancesDict = {};
        
        for (const key in in_data["balances"]) {
            if (in_data.hasOwnProperty(key)) {
                balancesDict[key] = in_data[key] ? { available: in_data[key], pending: 0 } : { available: null };
            }
        }
        
        const updated_data = { ...in_data, "balances": balancesDict };

        return updated_data;
    });

    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        console.log("GRID - addLeaveBalance", modified_data);

        const editResponse = await axios.post('http://localhost/api/set_leave_balances', modified_data, config);
        console.log("editResponse", editResponse);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("addLeaveBalance - ERROR TRY", error);
        return error.response
    }
};

export const editLeaveBalance = async (initial_data) => {    
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };


        // const data = {initial_data};
        // const data = {
        //     "id" : initial_data["id"],
        //     "name" : initial_data["name"],
        //     "accrual_type" : initial_data["accrual_type"],
        //     "accrual_days" : initial_data["accrual_days"],
        // };

        console.log("GRID - editLeaveBalance", initial_data);

        const editResponse = await axios.put('http://localhost/api/setLeaveBalances', initial_data, config);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editLeaveBalance - ERROR TRY", error);
        return error.response
    }
};

export const deleteLeaveRecord = async (id, to_delete) => {
    console.log(id, to_delete);
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        const data = {
            "id" : id,
            "to_delete" : to_delete
        };

        console.log("deleteLeaveRecord", data);
        
        const response = await axios.delete('http://localhost/api/delete-leave-record', {...config, data: data,});
        console.log("deleteResponse", response);

        return response

    } catch (error) {
        console.error("deleteLeaveRecord - ERROR TRY", error);
        return error.response;
    }
}

export const getLeaveTaken = async (employee, initialEvents) => {
    // console.log("GLT", employee)
    try {
        const response = await axios.get('http://localhost/api/leave-taken/'+employee.employee_number);
        let leave_taken = await response.data.results;
        // leave_taken = JSON.parse(leave_taken);
        // console.log("GLT LT", leave_taken);
        console.log("getLeaveTaken", response);


        if(!initialEvents.length){
            for (let i = 0; i < leave_taken.length; i++) {
                initialEvents.push(
                    {
                        id: leave_taken[i].id,
                        title: leave_taken[i].leave_type_id,
                        start: leave_taken[i].start_date,
                        end: format(addDays(new Date(leave_taken[i].end_date), 1), "yyyy-MM-dd"),
                        status: leave_taken[i].status,
                    });
            }
        }
        // console.log("IEEEE", initialEvents);

        return initialEvents;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log(err);
        console.log("somthing Wrong get Leave Taken");
    }
}

export const getApproveeLeaveTaken = async (employee, initialEvents) => {
    // console.log("GLT", employee)
    try {
        const response = await axios.get('http://localhost/api/approvee-leave-taken/'+employee.employee_number);
        const leave_taken = Object.values(response.data.results);
        // leave_taken = JSON.parse(leave_taken);
        // console.log("GLT LT", leave_taken);
        console.log("getApproveeLeaveTaken", response);

        const responseResults = changeDatesInsideResultsFromStringFormat(leave_taken);
    
        // if(!initialEvents.length){
        //     for (let i = 0; i < leave_taken.length; i++) {
        //         initialEvents.push(
        //             {
        //                 id: leave_taken[i].id,
        //                 title: leave_taken[i].leave_type_id,
        //                 start: leave_taken[i].start_date,
        //                 end: format(addDays(new Date(leave_taken[i].end_date), 1), "yyyy-MM-dd"),
        //                 status: leave_taken[i].status,
        //             });
        //     }
        // }
        // console.log("IEEEE", initialEvents);

        return responseResults;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log(err);
        console.log("somthing Wrong get Approvee Leave Taken");
    }
}

export const getLeaveGroups = async () => {
    try {
        const response = await axios.get('http://localhost/api/leave-groups/');
        console.log("getLeaveGroups", response);

        // const leave_groups = response.data.leave_groups;
        // const leave_types = response.data.leave_types;

        // let leave_type_ids = [];

        // leave_types.forEach((leave_type) => {
        //     leave_type_ids.push(leave_type["id"]);
        // });

        // const leave_groups_modified = leave_groups.map((group) => {
        //     const result = { ...group };
        
        //     leave_type_ids.forEach((id) => {
        //     result[id] = group["leave_type_ids"].includes(id) ? 1 : 0;
        //     });

        //     return result;
        // });

        return response.data.results;
        // return leave_groups_modified;
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get Groups");
    }
}

export const addLeaveGroup = async (initial_data) => {    
    const modified_data = {rows:[]};
        modified_data["rows"] = initial_data["rows"].map((data) => {
            const numberKeys = Object.entries(data)
                                        .filter(([key, value]) => !['id', "leave_type_ids", "created_at", "updated_at", "isNew"].includes(key) && ![0, "", false].includes(value))
                                        .map(([key]) => key);
            
            const updated_data = { ...data, "leave_type_ids": numberKeys };

            return updated_data;
    })

    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        console.log("GRID - addLeaveGroup", modified_data);

        const editResponse = await axios.post('http://localhost/api/add-leave-group', modified_data, config);
        // console.log("editResponse", editResponse);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("addLeaveGroup - ERROR TRY", error);
        return error.response
    }
};

export const editLeaveGroup = async (initial_data) => {    
    const modified_data = {rows:[]};
    modified_data["rows"] = initial_data["rows"].map((data) => {
        const numberKeys = Object.entries(data)
                                    .filter(([key, value]) => !['id', "leave_type_ids", "created_at", "updated_at", "isNew"].includes(key) && ![0, "", false].includes(value))
                                    .map(([key]) => key);
        
        const updated_data = { ...data, "leave_type_ids": numberKeys };

        return updated_data;
    })

    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };


        // const data = {initial_data};
        // const data = {
        //     "id" : initial_data["id"],
        //     "name" : initial_data["name"],
        //     "accrual_type" : initial_data["accrual_type"],
        //     "accrual_days" : initial_data["accrual_days"],
        // };

        console.log("GRID - editLeaveGroup", modified_data);

        const editResponse = await axios.put('http://localhost/api/edit_leave_group', modified_data, config);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editLeaveGroup - ERROR TRY", error);
        return error.response
    }
};

export const editEmployee = async (initial_data) => {    
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };


        // const data = {initial_data};
        // const data = {
        //     "id" : initial_data["id"],
        //     "name" : initial_data["name"],
        //     "accrual_type" : initial_data["accrual_type"],
        //     "accrual_days" : initial_data["accrual_days"],
        // };

        console.log("GRID - editEmployee", initial_data);

        const editResponse = await axios.put('http://localhost/api/edit_employee', initial_data, config);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("editEmployee - ERROR TRY", error);
        return error.response
    }
};

const changeDatesInsideResultsFromStringFormat = (results) => {
    const transformedData = results.map((dict) => {
        // Create a new object to avoid mutation
        const updatedDict = {};

        // Iterate over each key-value pair
        for (const [key, value] of Object.entries(dict)) {
            updatedDict[key] = ['start_date', 'end_date'].includes(key) ? new Date(value) : value;
        }

        return updatedDict;
    });

    return transformedData;
}

export const getAllLeaveTaken = async () => {
    try {
        const response = await axios.get('http://localhost/api/all-leave-taken/');
        console.log("getAllLeaveTaken", response);

        const responseDataResults = changeDatesInsideResultsFromStringFormat(response.data.results);
        
        return responseDataResults;
        // return response.data.results;
    } catch (err) {
        // console.log(err);
        console.log("somthing Wrong get AllLeaveTaken");
    }
};

export const actionLeaveTaken = async (initial_data) => {    
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        };

        console.log("GRID - actionLeave", initial_data);

        let editResponse = await axios.put('http://localhost/api/action-leave-taken', initial_data, config);
        console.log("editRESPONSE", editResponse);

        const transformedResultsData = changeDatesInsideResultsFromStringFormat(Object.values(editResponse.data.data));

        const transformedEditResponse = { ...editResponse, data: { ...editResponse.data, data: transformedResultsData } }

        return transformedEditResponse
        // return editResponse

    } catch (error) {
        console.error("actionLeave - ERROR TRY", error);
        return error.response
    }
};

export const getAccrualRuns = async () => {
    try {
        const response = await axios.get('http://localhost/api/accrual-runs');
        console.log("getAccrualRuns", response);
        return response.data.results;
        // setUSerData(result.data.results)
    } catch (err) {
        console.log("somthing Wrong Accrual Runs");
    }
};

export const runAccrualRun = async (initial_data) => {    
    try {
        const tokenResponse = await axios.get('http://localhost/api/get-csrf-token');
        const csrfToken = tokenResponse.data.csrfToken;
        // console.log("CSRF TOKEN", csrfToken);
        const config = {
            headers: {
            // 'Content-Type': 'application/json',
            // Authorization: 'Bearer your_token_here',
            'X-CSRF-TOKEN': csrfToken
            }
        }

        console.log("accrualRun", initial_data);

        const editResponse = await axios.post('http://localhost/api/accrual-run', initial_data, config);
        // console.log("editRESPONSE", editResponse.data);
        return editResponse

    } catch (error) {
        console.error("accrualRun - ERROR TRY", error);
        return error.response
    }
};

export const workDuration = (range, selectedLeave, leaveTypes, manualDuration) => {
    // console.log(manualDuration);
    const start_date = format(range[0].startDate, "yyyy-MM-dd");
    const end_date = format(range[0].endDate, "yyyy-MM-dd");
    const excludedDays = [];

    const filteredData = leaveTypes.filter((item) => item.id === selectedLeave);
    
    if((filteredData[0].sunday === 0) || (filteredData[0].sunday === null)){excludedDays.push(0);}  
    if((filteredData[0].monday === 0) || (filteredData[0].monday === null)){excludedDays.push(1);}  
    if((filteredData[0].tuesday === 0) || (filteredData[0].tuesday === null)){excludedDays.push(2);}  
    if((filteredData[0].wednesday === 0) || (filteredData[0].wednesday === null)){excludedDays.push(3);}  
    if((filteredData[0].thursday === 0) || (filteredData[0].thursday === null)){excludedDays.push(4);}  
    if((filteredData[0].friday === 0) || (filteredData[0].friday === null)){excludedDays.push(5);}  
    if((filteredData[0].saturday === 0) || (filteredData[0].saturday === null)){excludedDays.push(6);}

    // console.log("Excluded Days", excludedDays, selectedLeave);

    const getNumberOfDays = (startDate, endDate) => {
        let currentDate = new Date(startDate);
        const targetDate = new Date(endDate);
        let numberOfDays = 0;
      
        while (currentDate <= targetDate) {
          if (!excludedDays.includes(currentDate.getDay())) { // Tuesday has index 2
            numberOfDays++;
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
      
        return numberOfDays;
      }

      //if manual, calculate the fraction of the day from the given start_time and end_time based on the leaveType's start_time and end_time
      if(manualDuration.enabled){
        return getNumberOfDays(start_date, end_date);
        const start_time = format(range[0].startDate, "HH:mm");
        const end_time = format(range[0].endDate, "HH:mm");
        const leave_start_time = format(new Date(filteredData[0].start_time), "HH:mm");
        const leave_end_time = format(new Date(filteredData[0].end_time), "HH:mm");
        const start_time_in_minutes = new Date(start_time).getHours() * 60 + new Date(start_time).getMinutes();
        const end_time_in_minutes = new Date(end_time).getHours() * 60 + new Date(end_time).getMinutes();
        const leave_start_time_in_minutes = new Date(leave_start_time).getHours() * 60 + new Date(leave_start_time).getMinutes();
        const leave_end_time_in_minutes = new Date(leave_end_time).getHours() * 60 + new Date(leave_end_time).getMinutes();
        const duration_in_minutes = end_time_in_minutes - start_time_in_minutes;

        const leave_duration_in_minutes = leave_end_time_in_minutes - leave_start_time_in_minutes;
        const fraction_of_day = duration_in_minutes / leave_duration_in_minutes;
        console.log("FOD", fraction_of_day);
        return getNumberOfDays(start_date, end_date) - 1 + fraction_of_day;
      } else {
        // console.log("NOD", getNumberOfDays(start_date, end_date));
        return getNumberOfDays(start_date, end_date);
      }
    
}

